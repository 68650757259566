import React from "react";
import LogoImg from "assets/images/logo.png";
import { useSelector } from 'react-redux';
import { NavLink } from "react-router-dom";
import Com from "components/styledComp/CommStyledComp";
import useMenuBtnDropDown from "hooks/useMenuBtnDropDown";

const Header = () => {
    
    const titleList = useSelector(state=>{
        return state.HeaderTitles.value;
    });

    const [isOpen, opnBtnref, clsBtnref, myPageHandler] = useMenuBtnDropDown(false);

    return(
        <header className="header">
            <div className="header-inner">
                <h1>
                    <NavLink to="/">
                        {/* 로고이미지 */}
                        <img src={LogoImg} alt=""/>
                        <span hidden="hidden">한영디에스</span>
                    </NavLink>
                </h1>
                {/* 모바일일때 메뉴열기버튼 */}
                <button type="button" className="header-open" onClick={myPageHandler} ref={opnBtnref}>
                    <svg width="16" height="12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill="#232324" d="M0 0h16v2H0zM0 5h16v2H0zM0 10h16v2H0z"/>
                    </svg>
                </button>
                <div className={isOpen?"header-nav header-nav-open":"header-nav"}>
                    <div className="header-nav-top">
                        <NavLink className="header-nav-logo" to="/">
                            {/* 로고이미지 */}
                            <img src={LogoImg} alt=""/>
                        </NavLink>
                        {/* X버튼 */}
                        <Com.BtnNavBarClose type="button" onClick={myPageHandler} ref={clsBtnref}>
                            <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.6 7L0 12.6l1.402 1.398L7 8.4l5.6 5.6 1.398-1.402L8.4 7 14 1.4 12.598.002 7 5.6 1.4 0 .002 1.402 5.6 7z" fill="#232324"/>
                            </svg>
                        </Com.BtnNavBarClose>
                    </div>
                    <nav className="header-global">
                        <ul className="header-global-nav">
                            {/* map */}
                            {
                                titleList.map((id)=> 
                                    <li key={id.id}>
                                        <strong>
                                            <NavLink to={id.to}>{id.title}</NavLink>
                                        </strong>
                                    </li>
                                )
                            }
                        </ul>
                    </nav>
                    <div className="header-nav-foot">
                        <ul className="header-other">
                            <li>
                                <NavLink to="notice">공지사항</NavLink>
                            </li>
                            <li>
                                <NavLink to="location">오시는 길</NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;