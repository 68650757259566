import React from "react";
import SubPicture from "components/comp/SubPicture";
import MiddleImg from "components/comp/MiddleImg";
import laptopImg from "assets/images/laptop.jpg"
import CalendarImg from "assets/images/calendar.jpg"
import Phone from "assets/images/phone.jpg"
import PhoneBr from "assets/images/phoneboard.jpg"
import Keyboard from "assets/images/keyboard2.jpg"
import OperationWay from "components/comp/OperationWay";
import OperationWayOp from "components/comp/OperationWayOp";
import OperationStrategy from "components/comp/OperationStrategy";
import OperationApp from "components/comp/OperationApp";
import OperationSR from "components/comp/OperationSR";
import OperationER from "components/comp/OperationError";

const DetailCompetency = () => {

  return (
    <main className="company page-main company-more-wrap">
      <SubPicture img={laptopImg}/>
      <OperationWay/>
      <OperationWayOp/>
      <MiddleImg img={CalendarImg} width="100%" height="400px"/>
      <OperationStrategy/>
      <MiddleImg img={Phone} width="100%" height="400px"/>
      <OperationApp/>
      <MiddleImg img={PhoneBr} width="100%" height="400px"/>
      <OperationSR/>
      <MiddleImg img={Keyboard} width="100%" height="400px"/>
      <OperationER/>
    </main>
  );
};

export default DetailCompetency;
