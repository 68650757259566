import React from "react";

const MajorBusiness = () => {
  return (
          <div className="company-box majorbusiness">
            <div className="company-box-title">한영의<br/> 대표사업</div>
            <div className="company-box-content">
              <ul className="majorbusiness-list">
              <li>
                <div className="majorbusiness-list-title">ITO사업</div>
                <div className="majorbusiness-list-content">기업 혹은 공공기관의 어플리케이션 <br/> 전부 또는 일부를 위탁받아서 운영</div>
              </li>
              <li>
                <div className="majorbusiness-list-title">SI사업</div>
                <div className="majorbusiness-list-content">고객의 기존 업무용 시스템을 <br/> 통합 또는 신규 구축하는 사업</div>
              </li>
              <li>
                <div className="majorbusiness-list-title">솔루션 및 컨설팅사업</div>
                <div className="majorbusiness-list-content">영업, 구매, 제조, 물류 등 <br/>물동 부문 업무 프로세스 컨설팅 사업</div>
              </li>
              </ul>
            </div>
          </div>
  );
};

export default MajorBusiness;
