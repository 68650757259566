import { createSlice } from '@reduxjs/toolkit';

const initialStateValue =  [
  { 
    id : 1,
    to : 'about', 
    title: '회사소개', 
  },
  { 
    id : 2,
    to : 'competency',
    title: '핵심역량',
  },
  {
    id : 3,
    to : 'product', 
    title: '제품소개',
  },
  { 
    id : 4,
    to : 'recruit', 
    title: '인재채용',
  },
  { 
    id : 5,
    to : 'location', 
    title: '고객지원',
  },
];
 
const getHeaderTitleSlice = createSlice({
  name:'headerTitleSlice',
  initialState: {value : initialStateValue},
  reducers:{
    
    }
});



export default getHeaderTitleSlice;