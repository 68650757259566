import Com from "components/styledComp/CommStyledComp";
import CompImg from "components/comp/CompImg";
import Application from "assets/images/Application.png"
import React from "react";

const OperationApp = () => {
    return(
        <Com.DivDetailSection border="false">
            <Com.DivDetailHeading>
                <Com.DetailHeading>어플리케이션 운영</Com.DetailHeading>
                <Com.DetailHeadingP>유지보수 서비스</Com.DetailHeadingP>
                <Com.DetailHeadingSmall>어플리케이션 운영은(유지보수 서비스) <strong>1.어플리케이션 운영 2.어플리케이션 개발 3.어플리케이션 지원</strong><br/>
                                        3개 부문으로 나누고 13개 서비스 항목과 25개 상세 서비스 아이템으로 분류하여 각 서비스 아이템에 대해서<br/>
                                        정의와 책임사항, 산출물 등을 문서화 하여 체계적으로 서비스를 제공하고자 함</Com.DetailHeadingSmall>
            </Com.DivDetailHeading>
            <CompImg img={Application} width="100%" height="auto"/>
        </Com.DivDetailSection>
    );
}

export default OperationApp;