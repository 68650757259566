import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import "react-notion/src/styles.css";
import "prismjs/themes/prism-tomorrow.css"; // only needed for code highlighting
import { NotionRenderer } from "react-notion";
import Com from "components/styledComp/CommStyledComp";
import { NavLink } from "react-router-dom";

const BoardArticle = () => {

  const [response, setResponse] = useState({});

  const {pageId, type, date, title} = JSON.parse(localStorage.getItem("pageInfo"));
  
  useEffect(()=>{

    axios({
      url : '/api/getPage',
      params: {
        pageId: pageId,
      },
    }).then((res)=>{
      setResponse(res.data);
    })
  },[pageId])
  
  return (
    <>
      <Com.MainArticle>
        <Com.SectionArticle>
          <Com.DivArticleHead>
            <Com.DivArticleMeta>
              <span className="article-detail-category">
                <NavLink to={type==="SHOW"?"/notice":"/recruit"}>
                  {type==="SHOW"?"공지사항":"인재채용"}
                </NavLink>
              </span>
              <time className="article-detail-date">{date}</time>
            </Com.DivArticleMeta>
            <div className="article-detail-title">
              <strong>{title}</strong>
            </div>
          </Com.DivArticleHead>
          <div className="article-detail-content">
            {<NotionRenderer blockMap={response} fullPage={false}/>}
          </div>
        </Com.SectionArticle>
        <section>
          <Com.DivBtnWrap>
            <button className="btn btn-more">
              <NavLink to={type==="SHOW"?"/notice":"/recruit"}>
                목록 보기
              </NavLink>
            </button> 
          </Com.DivBtnWrap>
        </section>
      </Com.MainArticle>
    </>
  );
};

export default BoardArticle;
