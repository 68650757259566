import BoardList from "components/comp/BoardList";
import Com from "components/styledComp/CommStyledComp";

const Notice = () => {

  return (
    <>
      <main className="company page-main company-more-wrap">
        <section className="page-heading-wrap">
          <Com.H3PageHeadTitle>
            한영디에스 공지사항
          </Com.H3PageHeadTitle>
        </section>
        <section>
          <Com.DivBoardList>
            <BoardList type = {"SHOW"}/>
          </Com.DivBoardList>
        </section>
      </main>
    </>
  );
};

export default Notice;
