import React from "react";

const Loading = () => {

  return (
    <>
      <div className="loading">
        <i className="loading-1"/>
        <i className="loading-2"/>
        <i className="loading-3"/>
      </div>
    </>
  );
};

export default Loading;
