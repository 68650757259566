import Com from "components/styledComp/CommStyledComp";
import CompImgGrid from "components/comp/CompImg_Grid";
import SRProcessSM from "assets/images/SRProcess_small.png"
import React from "react";

const OperationSR = () => {
    return(
        <Com.DivDetailSection border="false">
            <Com.DivDetailHeading>
                <Com.DetailHeading>Services Request(S/R) 처리 프로세스</Com.DetailHeading>
            </Com.DivDetailHeading>
            <Com.DivImgSub>
                <div>
                    <CompImgGrid img={SRProcessSM} width="80%" height="auto"/>
                </div>
                <div>
                    <div className="strategy-sep-list">
                        <div className="strategy-sep">
                            <div className="strategy-title">
                                <Com.StrongStrategy>1. 서비스 요청(S/R) 및 등록</Com.StrongStrategy>
                                <p className="strategy-desc">
                                ㆍ현업에서 서비스 개선 요청(전화, e-Mail 등)<br/>
                                ㆍ개선 요청내용 등록(S/R번호 부여)<br/>
                                </p>
                            </div>
                        </div>
                        <div className="strategy-sep">
                            <div className="strategy-title">
                                <Com.StrongStrategy>2. 요청내용 검토 및 승인</Com.StrongStrategy>
                                <p className="strategy-desc">
                                ㆍ운영 담당자 검토<br/>
                                ㆍ고객 담당자에 요청내용 최종 검토 및 승인 협의<br/>
                                ㆍ기각 시 S/R 절차 종료
                                </p>
                            </div>
                        </div>
                        <div className="strategy-sep">
                            <div className="strategy-title">
                                <Com.StrongStrategy>3. 조치 계획 수립</Com.StrongStrategy>
                                <p className="strategy-desc">
                                ㆍ운영 업무담당자는 조치 일정 및 공수 등록<br/>
                                ㆍ고객 담당자와 협의 후 개선 실시
                                </p>
                            </div>
                        </div>
                        <div className="strategy-sep">
                            <div className="strategy-title">
                                <Com.StrongStrategy>4. 테스트 실시 및 실제 적용</Com.StrongStrategy>
                                <p className="strategy-desc">
                                ㆍ개선 후 최종 테스트 결과 보고<br/>
                                ㆍ고객의 최종 승인을 거쳐 운영 시스템에 적용
                                </p>
                            </div>
                        </div>
                        <div className="strategy-sep">
                            <div className="strategy-title">
                                <Com.StrongStrategy>5. S/R(Service Request) 종료</Com.StrongStrategy>
                                <p className="strategy-desc">
                                ㆍ운영 시스템 적용 후 안정화 여부 모니터링 실시<br/>
                                ㆍ요청 담당자에게 Happy Call 요청
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </Com.DivImgSub>
        </Com.DivDetailSection>
    );
}

export default OperationSR;