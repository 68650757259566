import React from "react";
import { NavLink } from "react-router-dom";

const GoDetail2 = (props) => {
  return (
    <button className="btn btn-grid">
      <NavLink to={props.to}>
        {props.page} 페이지로 가기
      </NavLink>
    </button>
  );
};

export default GoDetail2;
