import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import setHeaderNavSlice from "redux/reducers/setHeaderNavSlice";

// hook 
// 오픈 메뉴 버튼과 닫기 버튼이 각각 다른 컴포넌트에 위치할 때
// esc or 닫기버튼 클릭 or 닫기버튼이 포함된 영역의 외부를 누르면 닫히는 기능
const useMenuBtnDropDown = (initialState) => {
    const dispatch = useDispatch();

    const isOpen = useSelector(state=>{
        return state.HeaderNavState.value;
    });

    const opnBtnref = useRef(null);
    const clsBtnref = useRef(null);

    const removeHandler = () => {
        dispatch(setHeaderNavSlice.actions.setOpen(!isOpen));
    }

    useEffect(()=>{
        const onClick = (e) => {
            if (e.key === 'Escape') {
                e.preventDefault();
                dispatch(setHeaderNavSlice.actions.setOpen(!isOpen));
            }
            else if(opnBtnref.current!==null && opnBtnref.current.contains(e.target)){
                dispatch(setHeaderNavSlice.actions.setOpen(true));
            }
            else if(clsBtnref.current!==null && (clsBtnref.current.contains(e.target) || !e.target.contains(clsBtnref.current))){
                dispatch(setHeaderNavSlice.actions.setOpen(!isOpen));
            }
        };
        if(isOpen){
            window.addEventListener("click", onClick);
            window.addEventListener('keydown', onClick);
        }
        return () => {
            window.removeEventListener("click", onClick);
            window.removeEventListener('keydown', onClick);
        };
    },[isOpen, dispatch]);

    return [isOpen, opnBtnref, clsBtnref, removeHandler];
};

export default useMenuBtnDropDown;