import Com from "components/styledComp/CommStyledComp";
import BoardList from "components/comp/BoardList";

const Recruit = () => {

  return (
    <>
      <main className="company page-main company-more-wrap">
        <section className="page-heading-wrap">
          <Com.H3PageHeadTitle>
            한영디에스 채용공고
          </Com.H3PageHeadTitle>
        </section>
        <section>
          <Com.DivBoardList>
            <BoardList type1 = {"IN PROGRESS"} type2 = {"EXIT"}/>
          </Com.DivBoardList>
        </section>
      </main>
    </>
  );
};

export default Recruit;
