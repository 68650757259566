import Com from "components/styledComp/CommStyledComp";
import React from "react";

const Hello = () => {
    return(
        <Com.DivDetailSection border="false">
            <Com.DivDetailHeading>
                <Com.DetailHeading>대표 인사</Com.DetailHeading>
            </Com.DivDetailHeading>
            <div className="page-heading-desc">
                안녕하십니까<br/>
                먼저 관심을 갖고 우리 회사 홈페이지를 방문해 주심을 진심으로 감사드립니다.<br/><br/>
                한영(韓永)은 회사가 영원히 나아감을 희망하고 신뢰와 성실을 바탕으로 고객 중심의 서비스를<br/>
                최고의 가치로 생각하고, IT 기반 기술을 바탕으로 안정적 시스템 운영과 창조, 창의의 정신으로<br/>
                끊임없는 연구개발과 혁신으로 부가가치를 창출하는 토탈 솔루션을 고객사에 제공하고자 합니다.<br/><br/>
                또한 한영디에스(주)가 추구하는 시스템 유지보수는 영업, 구매, 제조, 물류 부분의 수많은<br/>
                시스템 구축 Know-how와 유지보수 관리 경험을 바탕으로 차별화된 최상의 서비스를 제공하고<br/>
                정보시스템의 신뢰성과 운영효율성을 확보하여 시스템 운영 품질 서비스를 극대화해 나가고자 합니다.<br/><br/>
                앞으로도 고객님의 성원 속에서 나날이 성장해나가는 모습을 지켜봐 주시고, 항상 신뢰를 바탕으로<br/>
                믿을 수 있는 서비스 제공을 위해 최선을 다할 것을 약속드립니다.<br/>
                댁내 항상 건강과 행복이 가득하시길 기원합니다.<br/>
                <br/>
                한영디에스 주식회사<br/>
                대표이사 황영포
            </div>
        </Com.DivDetailSection>
    );
}

export default Hello;