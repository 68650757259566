import Com from "components/styledComp/CommStyledComp";
import ErrorProcess from "assets/images/ErrorProcess.png"
import React from "react";

const OperationError = () => {
    return(
        <Com.DivGridDetailSection border="false">
            <Com.DivDetailHeading>
                <Com.DetailHeading>장애대응 프로세스</Com.DetailHeading>
            </Com.DivDetailHeading>
            <Com.DivImgSub2>
                <div>
                    <Com.DivCompImg>
                    <p>
                        <picture>
                        <Com.MiddleGridImg2 src={ErrorProcess} alt="" width="100%" height="auto"/>
                        </picture>
                    </p>
                    </Com.DivCompImg>
                </div>
                <div>
                    <div className="strategy-sep-list">
                        <div className="strategy-sep">
                            <div className="strategy-title">
                                <Com.StrongStrategy>1. 장애 등록 및 1차 보고</Com.StrongStrategy>
                                <p className="strategy-desc">
                                장애가 발생되면 즉시 시스템에 장애를 등록하고 유선으로 운영팀장에게 보고함
                                </p>
                            </div>
                        </div>
                        <div className="strategy-sep">
                            <div className="strategy-title">
                                <Com.StrongStrategy>2. 장애대응 TF 가동</Com.StrongStrategy>
                                <p className="strategy-desc">
                                운영팀장은 장애보고 접수 후 심각성을 판단하여 장애 대응 TF를 가동함
                                </p>
                                <p className="strategy-desc">
                                운영팀장은 한샘에 보고하고 장애 종료까지 지휘를 한다
                                </p>
                            </div>
                        </div>
                        <div className="strategy-sep">
                            <div className="strategy-title">
                                <Com.StrongStrategy>3. 장애 원인 분석 및 보고</Com.StrongStrategy>
                                <p className="strategy-desc">
                                운영 담당자는 즉시 장애 원인을 파악해서 장애대응 TF에 보고함
                                </p>
                            </div>
                        </div>
                        <div className="strategy-sep">
                            <div className="strategy-title">
                                <Com.StrongStrategy>4. 간단한 장애 즉 조치</Com.StrongStrategy>
                                <p className="strategy-desc">
                                P/G 개선이 필요하지 않은 경우는 즉시 조치함
                                </p>
                            </div>
                        </div>
                        <div className="strategy-sep">
                            <div className="strategy-title">
                                <Com.StrongStrategy>5. P/G 즉 조치 및 변경 이력 관리</Com.StrongStrategy>
                                <p className="strategy-desc">
                                시급히 P/G 변경이 필요한 장애의 경우 즉시 P/G 수정 조치한 후 변경 이력 등록 관리 함
                                </p>
                            </div>
                        </div>
                        <div className="strategy-sep">
                            <div className="strategy-title">
                                <Com.StrongStrategy>6. S/R 처리 프로세스에 따라 개선 진행</Com.StrongStrategy>
                                <p className="strategy-desc">
                                시급하지 않은 P/G 변경의 경우 S/R 처리 프로세스에 따라서 개선 진행함
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </Com.DivImgSub2>
        </Com.DivGridDetailSection>
    );
}

export default OperationError;