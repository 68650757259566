import React from "react";
import { useSelector } from 'react-redux';
import { NavLink } from "react-router-dom";

const Footer = () => {

  const titleList = useSelector(state=>{
    return state.FooterTitles.value;
  });

    return(
      <>
        <footer className="footer">
          <div className="footer-inner">
            <ul className="footer-nav">
            {/* map */}
            {
              //필터 적용하려면
              //titleList.filter(id => id.id !== 6).map((id)=>
              //if문 사용할때는 return 사용
              titleList.map((id)=> {
                if(id.id!==5){
                  return (
                    <li key={id.id}>
                      <strong>
                        <NavLink to={id.to}>{id.title}</NavLink>
                      </strong>
                      <ul>
                          {
                            id.subTitles.map((subId) => 
                              <li key={subId.subId}>
                                <NavLink to={subId.subTo}>{subId.subTitle}</NavLink>
                              </li>
                          )}
                        </ul>
                    </li>
                  )
                }
                else{
                  return (
                    <li className="other" key={id.id}>
                      <ul>
                        {/* 첫번째 map에서 키 물려받아 하위 map 반복 */}
                        {
                            id.subTitles.map((subId) => {
                              if(subId.subTo==="news"||subId.subTo==="questions"){
                                return (
                                  <li key={subId.subId}>
                                    <NavLink to={""} onClick={()=>alert('페이지 준비 중입니다.')}>{subId.subTitle}</NavLink>
                                  </li>
                                )
                              }else{
                                return (
                                  <li key={subId.subId}>
                                    <NavLink to={subId.subTo}>{subId.subTitle}</NavLink>
                                  </li>
                                )
                              }
                            }
                          )}
                      </ul>
                    </li>
                  )
                }
              })}
            </ul>
            <div className="footer-address">
              <h2>(주)한영디에스</h2>
              <address>
                <p>
                  <span>사업자 등록번호 : 593-88-02115</span>
                  <i>|</i>
                  <span>대표 : 황영포</span>
                  <i>|</i>
                  <span>주소 : 서울특별시 마포구 성암로 179(한샘상암사옥) 8층</span>
                </p>
              </address>
            </div>
          </div>
        </footer>
      </>
    );
}

export default Footer;