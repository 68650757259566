import React from "react";
import MajorBusiness from "components/comp/MajorBusiness";
import CompanyIntro from "components/comp/CompanyIntro";
import Hello from "components/comp/Hello";
import SubPicture from "components/comp/SubPicture";
import MiddleImg from "components/comp/MiddleImg";
import lapImg from "assets/images/lap.jpg"
import BuildingImg from "assets/images/building.jpg"

const DetailCompanyIntro = () => {

  return (
    <main className="company page-main company-more-wrap">
       <section className="company-main">
          <SubPicture img={lapImg}/>
          <Hello/>
          <MiddleImg img={BuildingImg} width="100%" height="400px"/>
          <CompanyIntro/>
          <MajorBusiness/>
      </section>
    </main>
  );
};

export default DetailCompanyIntro;
