import Com from "components/styledComp/CommStyledComp";
import React from "react";



const MiddleImg = ({img, width= "100%", height="100%"}) => {
  return (
    <Com.DivMiddleImg>
      <p>
        <picture>
          <Com.MiddleImg src={img} alt="" width={width} height={height}/>
        </picture>
      </p>
    </Com.DivMiddleImg>
  );
};

export default MiddleImg;
