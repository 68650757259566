import Com from "components/styledComp/CommStyledComp";
import React from "react";

const OperationWay = () => {
    return(
        <Com.DivDetailSection border="false">
            <Com.DivDetailHeading>
                <Com.DetailHeading>유지보수 운영방향</Com.DetailHeading>
                <Com.DetailHeadingSmall>안정적인 시스템 운영지원 및 고객 요구사항 만족을 통한 지속적인 서비스 질 개선</Com.DetailHeadingSmall>
            </Com.DivDetailHeading>
            <div className="preview-tag-list">
                <Com.BtnTagList width="170px" disabled="disabled"> 시스템 효율의 극대화 </Com.BtnTagList>
                <Com.BtnTagList width="85px" disabled="disabled"> 고객감동 </Com.BtnTagList>
                <Com.BtnTagList width="120px" disabled="disabled"> 시스템 안정성 </Com.BtnTagList>
            </div>
            <div className="preview-tag-list">
                <Com.BtnTagList width="170px" disabled="disabled"> 시스템의 신뢰성 확보 </Com.BtnTagList>
                <Com.BtnTagList width="135px" disabled="disabled"> 장애 시 즉각대처 </Com.BtnTagList>
            </div>
        </Com.DivDetailSection>
    );
}

export default OperationWay;