import styled from "styled-components";
import { NavLink } from "react-router-dom";

const Com = {};

Com.ImgMain = styled.img`
    position: absolute; 
    top:0; 
    left: 0;
    width:100%;
    height: 100%;
    background-attachment: fixed;
    background-position: 50% 50%;
    background-size: cover;
    filter: brightness(50%);
    object-fit:cover;
`

Com.DivMiddleImg = styled.div`
    width: 100%;
    margin: 0 auto 120px;
    position: relative;
    overflow: hidden;
    
    @media all and (max-width: 768px){
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
        margin: 0 auto 0px;
    }
`

Com.DivCompImg = styled.div`
    width: 100%;
    margin: 20px auto 20px;
    position: relative;
    overflow: hidden;
    
    @media all and (max-width: 768px){
        -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
        order: 2;
        margin: 0 auto 0px;
    }
`

Com.MiddleGridImg = styled.img`
    @supports ((-o-object-fit:cover) or (object-fit:cover)){
        width: ${props=>props.width};
        height: ${props=>props.height};
        -o-object-fit: cover;
        object-fit: cover;
        margin: auto;
        display: block;

        @media all and (max-width: 768px){
            width: 50%;
        }

        @media all and (max-width: 480px){
            width: 70%;
        }
    }
`

Com.MiddleGridImg2 = styled.img`
    @supports ((-o-object-fit:cover) or (object-fit:cover)){
        width: ${props=>props.width};
        height: ${props=>props.height};
        -o-object-fit: cover;
        object-fit: cover;
        margin: auto;
        display: block;

        @media all and (max-width: 768px){
            width: 80%;
        }

        @media all and (max-width: 480px){
            width: 90%;
        }
    }
`

Com.MiddleImg = styled.img`
    @supports ((-o-object-fit:cover) or (object-fit:cover)){
        width: ${props=>props.width};
        height: ${props=>props.height};
        -o-object-fit: cover;
        object-fit: cover;
        margin: auto;
        display: block;
    }
`

Com.DivDetailSection = styled.section`
    max-width: 780px;
    margin: 80px auto;

    @media all and (max-width: 768px){
        margin: 6px auto 60px;
        padding: 80px 50px 20px;
        position: relative;
        border-top: ${props=>
            props.border==="false"?"0px solid" :"8px solid #ebeef2"
            
    }
    }

    @media all and (max-width: 768px){
        padding: 80px 20px 20px;
    }
`

Com.DivGridDetailSection = styled.section`
    max-width: 810px;
    margin: 80px auto;

    @media all and (max-width: 768px){
        margin: 6px auto 60px;
        padding: 80px 50px 20px;
        position: relative;
        border-top: ${props=>
            props.border==="false"?"0px solid" :"8px solid #ebeef2"
            
    }
    }

    @media all and (max-width: 768px){
        padding: 80px 20px 20px;
    }
`

Com.DivDetailHeading = styled.div`
    padding: 0 24px;
    text-align: center;
    word-break: keep-all;
    margin-bottom: 15px;

    @media all and (max-width: 768px){
        padding: 0 24px;
        text-align: center;
        word-break: keep-all;
    }
`
    
Com.DivImgSub = styled.div`
    
    margin-bottom: 3px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 20px;
    padding-top: 40px;

    @media all and (max-width: 768px){
        grid-template-columns: none;
    }
    
`

Com.DivImgSub2 = styled.div`
    
    margin-bottom: 3px;
    display: grid;
    grid-template-columns: 1.3fr 1fr;
    margin-top: 20px;
    padding-top: 40px;

    @media all and (max-width: 768px){
        grid-template-columns: none;
    }
    
`

Com.DetailHeading = styled.h3`
    
    margin-bottom: 3px;
    font-size: 30px;
    font-family: 'Esamanru';
    font-weight: 500;
    line-height: 1.5;

    @media all and (max-width: 768px){
        font-size: 30px;
        line-height: 1.4;
    }
`

Com.DetailHeadingP = styled.p`
    
    margin-bottom: 25px;
    font-size: 14px;
    font-family: 'Esamanru';
    font-weight: 500;
    line-height: 1.5;
    color: #393939;

    @media all and (max-width: 768px){
        font-size: 30px;
        line-height: 1.4;
    }
`

Com.DetailHeadingSmall = styled.small`
    line-height: 1.75;
    font-size: 18px;
    font-family: 'Pretendard';
    font-weight: 300;
    letter-spacing: -.07em;
    word-spacing: 0.05em;
`

Com.StrongStrategy = styled.strong`
    font-size: 20px; 
    font-family: 'Esamanru';
    font-weight: 500;
    line-height: 30px;
    letter-spacing: -0.3px;
    position: relative;
    top: 2px;
    //text-decoration: ${props=>props.underline===true?"underline":"none"};
    border-bottom: ${props=>props.underline===true?"1.5px solid #272727":"none"};
`

Com.SpanStrategy = styled.strong`
    font-size: 18px;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    margin-top: 4px;
    margin-right: 12px;
    color: #fff;
    font-weight: 500;
    border-radius: 50%;
    background-color: #232324;
    font-family: "Esamanru";
`
Com.BtnTagList = styled.button`
    color: black;
    font-size: 17px;
    font-weight: 400;
    margin: 10px 8px 0;
    border-radius: 18px;
    background-color: white;
    border: 1px solid;
    border-color: #A4A4A4;
    cursor: default;
    font-family: 'Pretendard';
    font-weight: 500;
    font-size: 17px;
    width: ${props=>props.width};
    height: 40px;

    @media all and (max-width: 480px){
        width: 170px;
    }
`

Com.BtnNavBarClose = styled.button`

    svg {
        vertical-align: top;
        width: 14px;
        height: 14px;
    }
    @media all and (max-width: 1024px) {
        line-height: 0;
        margin-right: -16px;
        padding: 16px;
    }
`

Com.H3PageHeadTitle = styled.h3`
    text-align: center;
    color: #232324;
    font-size: 30px;
    font-weight: 700;
    line-height: 1.5238095238;
    font-family: 'Esamanru';
    font-weight: 500;
    margin-top: 20px;
`
Com.DivMap = styled.div`
    width : 600px;
    height: 400px;
    margin: 0 auto;
    object-fit: cover;
`;

Com.OlBangHyang = styled.ol`
    display: grid;
    grid-gap: 60px 0px;
    grid-template-columns: 1fr 1fr;
    max-width: 768px;
    padding-left: 50px;

    li {
        margin-left: auto;
        margin-right: auto;
    }

    @media all and (max-width: 768px){
        display: grid;
        text-align: center;
        grid-gap: 20px;
        position: relative;
        padding: inherit;
    }

    @media all and (max-width: 480px){
        grid-template-columns: 3fr;
    }
`

Com.POpWay = styled.p`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: ${props=>props.width};
    padding: 0 15px;
    // line-height: 1.7142857143;
    color: white;
    background-color: black;
    border-radius: 18px;
    font-family: 'Esamanru';
    font-weight: 500;
    font-size: 20px;
`;

Com.OlInOl = styled.ol`
    flex: none;
    width: 100%;
    margin-top: 20px;
    counter-reset:list-number;
    text-align: left;
    font-family: 'Pretendard';
    font-weight: 300;
    font-size: 17px;

    li {
        margin-bottom: 14px;
        counter-increment:list-number;
    }

    li:before {
        content: counter(list-number) '. ';
    }
`;

Com.DivBoardList = styled.div`
    max-width: 1020px;
    margin: 0 auto 60px;
    padding: 0 50px;

    @media all and (max-width: 768px){
        margin: 0 24px 80px;
    }
`

Com.DivBoardBox = styled.div`
    border-bottom: 1px solid #f3f4f5;
`

Com.LinkBoard = styled(NavLink)`
    display: flex;
    flex-wrap: wrap;
    padding: 40px 20px;

    @media all and (max-width: 768px){
        position: relative;
        padding: 30px 0;
    }
`

Com.DivBoardContent = styled.div`
    -webkit-box-flex: 1;
    flex: 1;
    overflow: hidden;

    @media all and (max-width: 768px){
        position: relative;
    }
`

Com.DivBoardTitle = styled.div`
    font-weight: 700;
    display: -webkit-box;
    color: #232324;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    max-height: 64px;
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 1.6;
    font-family: 'Pretendard';

    @media all and (max-width: 768px){
        max-height: 52px;
        margin-bottom: 5px;
        font-size: 16px;
        line-height: 1.625;    
    }
`

Com.TimeBoard = styled.time`
    position: relative;
    color: #a6a7a9;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.7142857143;
    font-family: 'Pretendard';

    @media all and (max-width: 768px){
        font-size: 12px;
        line-height: 1.6666666667;
    }
`

Com.DivLoading = styled.div`
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    min-height: 200px;
    padding: 60px;
`

Com.MainArticle = styled.main`
    padding-bottom: 160px;
    margin-top: 0;
    padding-top: 72px;

    @media all and (max-width: 1024px){
        padding-top: 50px;
    }
`

Com.SectionArticle = styled.section`
    max-width: 780px;
    margin: 0 auto 0;
    padding-top: 80px;

    @media all and (max-width: 768px){
        margin-bottom: 120px;
        padding-top: 60px;
    }
`

Com.DivArticleHead = styled.div`
    padding-bottom: 80px;
    text-align: center;
    border-bottom: 1px solid #d6d7da;

    @media all and (max-width: 768px){
        padding: 0 24px 80px;
        border-bottom: 10px solid #ebeef2;
    }
`

Com.DivArticleMeta = styled.div`
    margin-bottom: 20px;
`

Com.DivBtnWrap = styled.div`
    text-align: center;
    font-family: 'Pretendard';
    font-weight: 400;
    font-size: 17px;
`
export default Com;