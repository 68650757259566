import Com from "components/styledComp/CommStyledComp";
import React from "react";


const OperationStrategy = () => {
    return(
        <Com.DivDetailSection>
            <Com.DivDetailHeading>
                <Com.DetailHeading>유지보수 운영전략</Com.DetailHeading>
            </Com.DivDetailHeading>
            <div className="strategy-list">
                <div className="strategy">
                    <Com.SpanStrategy>1</Com.SpanStrategy>
                    <div className="strategy-title">
                        <Com.StrongStrategy>위기관리 능력의 체계화, 전문화</Com.StrongStrategy>
                        <p className="strategy-desc">
                            고객사의 업무 리스크 발생 시 각 운영담당자와 밀착 지원 체계 구축<br/>
                            긴급사항 발생 시 전사적인 지원을 통해 업무 지원 체계 구축<br/>
                            시스템별 비상연락망을 통해 체계적이고 신속한 장애 대응 체제 구축
                        </p>
                    </div>
                </div>
                <div className="strategy">
                    <Com.SpanStrategy>2</Com.SpanStrategy>
                    <div className="strategy-title">
                        <Com.StrongStrategy>업무관련 부서와 유기적인 협조 체계 구축</Com.StrongStrategy>
                        <p className="strategy-desc">
                            업무 관련 부서와의 친화적인 업무 협력관계 유지<br/>
                            (고객사 정보전략, 고객사 인프라 운영, 한영디에스 운영 조직)<br/>
                            어플리케이션 개발 및 소통 경험이 풍부한 그룹장 및 운영팀장 배치
                        </p>
                    </div>
                </div>
                <div className="strategy">
                    <Com.SpanStrategy>3</Com.SpanStrategy>
                    <div className="strategy-title">
                        <Com.StrongStrategy>전문 수행조직 및 인력 구성</Com.StrongStrategy>
                        <p className="strategy-desc">
                            대상 시스템을 특징별로 그룹화하여 효율적이고 전문적으로 운영<br/>
                            주기적인 업무 로테이션을 통한 Multi Player 육성<br/>
                            SLA 기반의 서비스 관리 체계 정립
                        </p>
                    </div>
                </div>
                <div className="strategy">
                    <Com.SpanStrategy>4</Com.SpanStrategy>
                    <div className="strategy-title">
                        <Com.StrongStrategy>사용 편의성 및 개발 효율 극대화를 위한<br/>표준화, 공용화</Com.StrongStrategy>
                        <p className="strategy-desc">
                            최적의 사용자 편의성을 고려한 서비스 제공<br/>
                            일관된 사용자 인터페이스 개발<br/>
                            객체지향 개념의 표준화, 공용화로 개발 생산성 향상
                        </p>
                    </div>
                </div>
            </div>
        </Com.DivDetailSection>
    );
}

export default OperationStrategy;