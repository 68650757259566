import React from "react";
import Map from "components/comp/Map";
import Com from "components/styledComp/CommStyledComp";

const Location = () => {

  return (
    <main className="company page-main company-more-wrap">
      <section className="page-heading-wrap">
        <Com.H3PageHeadTitle>
          오시는 길
        </Com.H3PageHeadTitle>
        <p className="page-heading-desc">디지털미디어시티역 8번 출구 앞 한샘상암사옥 8층</p>
       </section>
      <section>
          <Map/>
        <div className="company-location">
          <div className="location-content">
          <ul className="location-list">
              <li>
                <div className="location-list-title">Address</div>
                <div className="location-list-cont">
                  우편번호 03929<br/> 
                  서울특별시 마포구 성암로 179(한샘상암사옥) 8층<br/>
                  지번 : 상암동 1623
                </div>
              </li>
            <li>
              <div className="location-list-title">FAX</div>
              <div className="location-list-cont">
                0504-133-8287<br/>
                02-2665-7877
              </div>
            </li>
            <li>
              <div className="location-list-title">TEL</div>
              <div className="location-list-cont">
                <a href="tel:02-2666-7730">02-2666-7730</a>
              </div>
            </li>
            <li>
              <div className="location-list-title">E-mail</div>
              <div className="location-list-cont">
                <a href="mailto:hyp6511@hyds.kr">hyp6511@hyds.kr</a>
              </div>
            </li>
          </ul>
          </div>
        </div>
      </section>
       
    </main>
  );
};

export default Location;
