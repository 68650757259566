import Com from "components/styledComp/CommStyledComp";

import React from "react";

//잡샵 스케쥴링 엔진 개요
const ScheduleJobShop = () => {
  return (
    <Com.DivDetailSection border="false">
      <Com.DivDetailHeading>
        <Com.DetailHeading>잡샵 스케쥴링 엔진 개요</Com.DetailHeading>
      </Com.DivDetailHeading>
      <div className="strategy-list">
        <div className="strategy">
          <div className="schedule-dogyo">
            <Com.StrongStrategy underline={true}>
              Job Shop 스케쥴링 알고리즘 개요
            </Com.StrongStrategy>
            <div className="strategy-desc">
              <p>
                Job Shop 스케쥴링 알고리즘은 작업(Job), 공정(Operation),
                기계(Machine) 간의 관계를 설정하여 각 작업을 완성할 수 있도록 각
                작업의 해당 공정을 기계에 할당하여 작업 전체의 스케쥴링 문제를
                해결하는 방법이다.
                <br />
              </p>
            </div>
          </div>
        </div>
        <div className="strategy">
          <div className="schedule-dogyo">
            <Com.StrongStrategy underline={true}>
              개선된 GA-FJSP 알고리즘 개발
            </Com.StrongStrategy>
            <div className="strategy-desc">
              <p>
                <span>Genetic Algorithm-Flexible Job Shop Problem</span>
              </p>
              <p>
                유전자 알고리즘을 이용하여 각 작업의 공정을 기계에 할당할 때
                공정별로 작업가능한 다수개의 기계들 중에서 선택하여 작업을
                할당할 수 있도록 하여 생산 스케쥴링 문제를 해결하는 알고리즘
                개발
              </p>
            </div>
          </div>
        </div>
        <div className="strategy">
          <div className="schedule-dogyo">
            <Com.StrongStrategy underline={true}>
              GA-LSTM 알고리즘 개발
            </Com.StrongStrategy>
            <div className="strategy-desc">
              <p>
                <span>Genetic Algorithm-Long Short Term Memory</span>
              </p>
              <p>
                소규모의 데이터 샘플을 가지고 유전자 알고리즘을 사용하여 트레인
                샘플을 만들고, SS-LSTM 딥러닝 방법을 사용하여 예측 모델을
                생성하여 대규모 데이터의 스케쥴링 문제를 해결하는 모델 개발
              </p>
            </div>
          </div>
        </div>
        {/* 
          <div className="strategy">
              <div className="schedule-dogyo">
                  <Com.StrongStrategy underline={true}> Deep Dynamic Job Shop Problem(향후)</Com.StrongStrategy>
                  <p className="strategy-desc">
                  </p>
              </div>
            </div> 
        */}
      </div>
    </Com.DivDetailSection>
  );
};

export default ScheduleJobShop;
