// MapContainer.js
import React, { useRef, useEffect } from "react";
import Com from "components/styledComp/CommStyledComp";

const { kakao } = window;
//위도경도셋팅
let lat = 37.57621916672;
let lan = 126.89871795274

const options = {
    //지도를 생성할 때 필요한 기본 옵션
    center: new window.kakao.maps.LatLng(lat,lan), //지도의 중심좌표.
    level: 3, //지도의 레벨(확대, 축소 정도)
  };

const Map = () => {
    const container = useRef(null); //지도를 담을 영역의 DOM 레퍼런스

    useEffect(() => {
        let map = new kakao.maps.Map(container.current, options); //지도 생성 및 객체 리턴

        map.setDraggable(true); //드래그 막기
        map.setZoomable(true); //줌 막기

        let markerPosition  = new kakao.maps.LatLng(lat,lan); //마커위치 셋팅
        let marker = new kakao.maps.Marker({
            position: markerPosition
        });
        // 마커가 지도 위에 표시되도록 설정합니다
        marker.setMap(map);
        return () => {};
    }, []);

    return (
        <Com.DivMap className="map" ref={container}/>
    );
}

export default Map; 