import Com from "components/styledComp/CommStyledComp";
import React from "react";

const OperationWayOp = () => {
    return(
        <Com.DivDetailSection>
            <div className="op_content">
            <Com.OlBangHyang>
                    <li>
                        <Com.POpWay width="100px">인적자원</Com.POpWay>
                        <Com.OlInOl>
                            <li>전문인력 투입</li>
                            <li>고객 감동의 마음가짐</li>
                            <li>인력 Pool 운영</li>
                            <li>Multi Player 운영</li>
                            <li>시스템 개선 제안</li>
                        </Com.OlInOl>
                    </li>
                    <li>
                        <Com.POpWay width="100px">업무지원</Com.POpWay>
                        <Com.OlInOl>
                            <li>정보전략 / IT  기획 지원</li>
                            <li>서비스 수준 관리(SLA)</li>
                            <li>어플리케이션 보안 관리</li>
                            <li>소프트웨어 기술력</li>
                            <li>운영 기술력</li>
                        </Com.OlInOl>
                    </li>
                    <li>
                        <Com.POpWay width="190px">어플리케이션 운영</Com.POpWay>
                        <Com.OlInOl>
                            <li>S/R, C/R 서비스</li>
                            <li>장애예방 / 재해 복구 관리</li>
                            <li>형상 관리 / 용량 관리</li>
                            <li>일상 점검 체크 리스트 관리</li>
                        </Com.OlInOl>
                    </li>
                    <li>
                        <Com.POpWay width="190px">어플리케이션 개발</Com.POpWay>
                        <Com.OlInOl>
                            <li>프로젝트 개발 관리</li>
                            <li>프로젝트 개발 수행</li>
                        </Com.OlInOl>
                    </li>
                </Com.OlBangHyang>
            </div>
        </Com.DivDetailSection>
    );
}

export default OperationWayOp;