import { createSlice } from '@reduxjs/toolkit';

const initialStateValue =  [
  { 
    id : 1,
    to : 'about', 
    title: '회사소개', 
    subTitles : [
      {
        subId : '1',
        subTo : 'about',
        subTitle : '인사말',
      },
      {
        subId : '2',
        subTo : 'about',
        subTitle : '주요사업 및 성과',
      },
      {
        subId : '3',
        subTo : 'about',
        subTitle : '회사연혁',
      },
    ],
  },
  { 
    id : 2,
    to : 'competency',
    title: '핵심역량',
    subTitles : [
      {
        subId : '1',
        subTo : 'competency',
        subTitle : '유지보수 운영방향',
      },
      {
        subId : '2',
        subTo : 'competency',
        subTitle : '유지보수 운영전략',
      },
      {
        subId : '3',
        subTo : 'competency',
        subTitle : '유지보수 서비스',
      },
      {
        subId : '4',
        subTo : 'competency',
        subTitle : '서비스 처리 프로세스',
      },
      {
        subId : '5',
        subTo : 'competency',
        subTitle : '형상관리 프로세스',
      },
    ],
  },
  {
    id : 3,
    to : 'product', 
    title: '제품소개',
    subTitles : [
      {
        subId : '1',
        subTo : 'product',
        subTitle : '계획 스케줄링',
      },
    ],
  },
  { 
    id : 4,
    to : 'recruit', 
    title: '인재채용',
    subTitles : [
      {
        subId : '1',
        subTo : 'recruit',
        subTitle : '채용 계획',
      },
    ],
  },
  { 
    id : 5,
    to : 'other', 
    title: '기타',
    subTitles : [
      {
        subId : '1',
        subTo : 'notice',
        subTitle : '공지사항',
      },
      {
        subId : '2',
        subTo : 'location',
        subTitle : '오시는 길',
      },
      {
        subId : '3',
        subTo : 'news',
        subTitle : '회사소식',
      },
      {
        subId : '4',
        subTo : 'questions',
        subTitle : '문의사항',
      },
    ],
  },
];
 
const getFooterTitleSlice = createSlice({
  name:'footerTitleSlice',
  initialState: {value : initialStateValue},
  reducers:{
    
    }
});



export default getFooterTitleSlice;