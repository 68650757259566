import React from "react";

const CompanyIntro = () => {
  return (
        <div className="company-box intro">
          <div className="company-box-title">
            <h3>한영 소개</h3>
            <p>토탈 솔루션 기업체</p>
          </div>
          <div className="company-box-content">
            <div className="paragraph">
              한영디에스(주)는 ITO사업, SI사업, 솔루션 및 프로세스 컨설팅 등
              <br/>
              3가지 분야를 대표 비즈니스로 최적화된 서비스를 제공하는
              <br/>
              프로세스 혁신과 시스템 구축이 모두 가능한 토탈 솔루션 기업체입니다.
            </div>
          </div>
        </div>
  );
};

export default CompanyIntro;
