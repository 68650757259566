import Com from "components/styledComp/CommStyledComp";
import React from "react";

const ScheduleAlgo = () => {
  return (
    <Com.DivDetailSection border="false">
      <Com.DivDetailHeading>
        <Com.DetailHeading>
          유전자 알고리즘 기반
          <br />
          섬유 제직 공정 스케쥴링 플랫폼 사례
        </Com.DetailHeading>
      </Com.DivDetailHeading>
      <div className="strategy-list">
        <div className="strategy">
          <div className="schedule-dogyo">
            <Com.StrongStrategy underline={true}>개요</Com.StrongStrategy>
            <div className="strategy-desc">
              <p>
                의류 또는 신발을 만들기 위해 직물을 생산하는 섬유 제직과정에서
                구매자와 생산자간의 주문 서비스 플랫폼이다. 구매자는 주문한 제품
                작업 현황을 모니터링할 수 있고 구매자의 조건에 적합한 생산자들의
                정보를 제공받는다. 생산자는 구매자가 주문한 다수의 제품에 대해,
                잡샵 스케쥴링 엔진으로부터 생산 가능한 최소 소요시간을 고려하여
                생산 가능한 제품들의 스케쥴을 제공받는다.
                <br />
                최종적으로, 구매자는 생산자들이 제공한 생산 가능한 제품 정보를
                이용하여 원하는 제품을 해당 생산자에게 주문한다.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Com.DivDetailSection>
  );
};

export default ScheduleAlgo;
