import React from "react";

const GoDetailUrl = () => {

  const url = "http://schedule.hyds.kr/"

  return (
    <button className="btn btn-grid" onClick={()=>{window.open(url)}}>
      섬유 공정 스케줄러 바로가기
    </button>
  );
};

export default GoDetailUrl;
