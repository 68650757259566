import Com from "components/styledComp/CommStyledComp";
import React from "react";
import CompImg from "./CompImg";
import B1 from "assets/images/B2B_1.png";

//스케쥴링 시스템 기능
const ScheduleProcess = () => {
    return(
        <Com.DivDetailSection border="false">
            <Com.DivDetailHeading>
                <Com.DetailHeading>스케쥴링 시스템 기능</Com.DetailHeading>
            </Com.DivDetailHeading>
            <div className="strategy-list">
                <div className="strategy">
                    <div className="schedule-dogyo">
                        <Com.StrongStrategy underline={true}>Job Shop 스케쥴링을 이용한 구매자와 생산자 매칭 구조도</Com.StrongStrategy>
                        <div className="strategy">
                            <CompImg img={B1} width="100%" height="auto"/>
                        </div>
                    </div>
                </div>
                <div className="strategy">
                    <div className="schedule-dogyo">
                        <Com.StrongStrategy underline={true}>등록 프로세스</Com.StrongStrategy>
                        <div className="strategy-desc">
                            <p>
                                사용자는 구매자, 생산자로 분류한다. 구매 및 생산별로 각 사용자는 회사 정보를<br/>
                                등록하고 시스템 관리자의 인증에 의해 로그인이 가능하다. 또한, 각 회사별로 여러명의<br/>
                                사용자 등록이 가능하다.<br/>
                            </p>
                            <p>
                                생산 사용자는 회사별 설비정보(설비명, 기능, 제조일자, 구매일자 등)를 등록해야 한다.<br/>
                                그리고 생산 가능한 제품의 이름, 특징, 평균가격 등의 제품 정보를 등록해야 한다.<br/>
                            </p>
                            <p>   
                                섬유 공정에서는 제품에 따라 작업 설비의 밀도, 평균 RPM, 일일 생산률 등의<br/>
                                정보가 정해진다.<br/>
                            </p> 
                        </div>
                    </div>
                </div>
                <div className="strategy">
                    <div className="schedule-dogyo">
                        <Com.StrongStrategy underline={true}>주문 프로세스</Com.StrongStrategy>
                        <p className="strategy-desc">
                            구매사용자는 주문하는 제품, 가격, 수량, 납기일자, 기업정보 등을 입력하여<br/>
                            주문하고 소속된 회사를 지정한다. 우선, 기업이 등록되지 않았을 경우 등록해야 한다.<br/>
                        </p>
                    </div>
                </div>
                <div className="strategy">
                    <div className="schedule-dogyo">
                        <Com.StrongStrategy underline={true}>스케쥴링 프로세스</Com.StrongStrategy>
                        <div className="strategy-desc">
                            <p>
                                구매사용자들의 주문에 따라 생산자는 작업을 설정한다.<br/>
                                (다수의 주문에 대해 다수의 생산자 지정 가능)<br/>
                            </p>
                            <p>
                                작업별로 해당 설비를 할당하고 작업별, 설비별 소요시간을 입력하면 작업별로<br/>
                                스케쥴을 간트챠트로 시각화하여 제공한다.(최적의 작업시간을 고려함)<br/>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="strategy">
                    <div className="schedule-dogyo">
                        <Com.StrongStrategy underline={true}>모니터링 프로세스(대시보드)</Com.StrongStrategy>
                        <div className="strategy-desc">
                            <p>
                                구매사용자는 어떤 생산자가 작업계획을 세우고 있는지 그리고 현재 주문중인<br/>
                                현황을 확인한다.<br/>
                            </p>
                            <p>
                                구매사용자는 스케쥴 가능한 업체를 추천하면 원하는 회사를 선택하여 수락한다.<br/>
                                생산 사용자는 실시간으로 주문 중에 있는 생산현황을 확인한다.<br/>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="strategy">
                    <div className="schedule-dogyo">
                        <Com.StrongStrategy underline={true}>비딩&매칭 프로세스</Com.StrongStrategy>
                        <p className="strategy-desc">
                            다수의 구매 사용자가 생산 계획을 제시할 경우 회사별 제품생산비용, 회사 신뢰도,<br/>
                            작업 완료일자의 우선순위로 생산회사를 매칭하여 제시한다.<br/>
                        </p>
                    </div>
                </div>
                <div className="strategy">
                    <div className="schedule-dogyo">
                        <Com.StrongStrategy underline={true}>평가</Com.StrongStrategy>
                        <div className="strategy-desc">
                            <p>
                                구매사용자는 오더가 체결되고 제품 수령 후 회사의 신뢰도를 평가하여 매칭 결정에<br/>
                                도움이 되도록 한다.<br/>
                            </p>
                            <p>각회사별 신뢰도는 스케쥴 별 평가 점수를 평균해서 산정한다.<br/></p>
                        </div>
                    </div>
                </div>
            </div>
        </Com.DivDetailSection>
    );
}

export default ScheduleProcess;