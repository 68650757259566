import {configureStore} from '@reduxjs/toolkit';
import getFooterTitleSlice from './reducers/getFooterTitleSlice';
import getHeaderTitleSlice from './reducers/getHeaderTitleSlice';
import getNotionDbSlice from './reducers/getNotionDbSlice';
import setHeaderNavSlice from './reducers/setHeaderNavSlice';

const store = configureStore({
  reducer:{
    //header(헤더메뉴에 들어가는 값들을 맵으로 불러오기 위해)
    HeaderTitles: getHeaderTitleSlice.reducer,
    //footer(푸터메뉴에 들어가는 값들을 맵으로 불러오기 위해)
    FooterTitles: getFooterTitleSlice.reducer,
    //header-nav(모바일메뉴일때와 pc메뉴 구분) open or close state 저장
    HeaderNavState: setHeaderNavSlice.reducer,
    //notion 데이터베이스를 비동기식으로 불러옴
    getNotionDb: getNotionDbSlice.reducer,
  }
});

export default store;