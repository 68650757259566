import React from "react";


const SubPicture = (props) => {
  return (
    <section className="company-visual">
        <picture>
          <img src={props.img} alt=""></img>
        </picture>
      </section>
  );
};

export default SubPicture;
