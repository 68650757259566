import { createSlice } from '@reduxjs/toolkit';
 
const setHeaderNavSlice = createSlice({
  name:'setHeaderNavSlice',
  initialState: {value : false},
  reducers:{
      setOpen:(state,action)=>{
        state.value = action.payload;
      }
    }
});

export default setHeaderNavSlice;