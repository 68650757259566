import Com from "components/styledComp/CommStyledComp";
import B2 from "assets/images/B2B_2.png";
import React from "react";
import CompImg from "./CompImg";

//B2B기반 스케쥴링 시스템 개요
const ScheduleDogyo = () => {
    return(
        <Com.DivDetailSection border="false">
            <Com.DivDetailHeading>
                <Com.DetailHeading>B2B기반 스케쥴링 시스템 개요</Com.DetailHeading>
            </Com.DivDetailHeading>
            <div className="strategy-list">
                <div className="strategy">
                    <div className="schedule-dogyo">
                        <Com.StrongStrategy underline={true}>시스템 목표</Com.StrongStrategy>
                        <div className="strategy-desc">
                            <p>생산자와 구매자를 B2B로 연계하여 스마트 협업이 가능한 서비스 플랫폼을 제공함<br/></p>
                            <p>
                            구매자의 다양한 요구와 생산자의 기업 환경 변화, 즉 주문 변경, 생산우선수위,<br/>
                            기계 고장 등을 고려하여 실시간 생산 스케쥴링을 시각화하여 제공한 정보를<br/>
                            바로 생산 현장에서 사용할 수 있는 시스템 개발<br/>
                            </p>
                        </div> 
                    </div>
                </div>
                <div className="strategy">
                    <div className="schedule-dogyo">
                        <Com.StrongStrategy underline={true}>개발기술</Com.StrongStrategy>
                        <div className="strategy-desc">
                            <p>개선된 flexible job shop 알고리즘, 딥러닝 기반 SS-LSTM 알고리즘 사용<br/>
                            다속성 역경매 의사 결정 모델를 이용한 매칭 알고리즘</p>
                        </div>
                    </div>
                </div>
                <div className="strategy">
                    <div className="schedule-dogyo">
                        <Com.StrongStrategy underline={true}>주요기능</Com.StrongStrategy>
                        <div className="strategy-desc">
                            <p>
                                <span>등록, 주문, 스케쥴링: </span>구매자와 생산자의 기본 정보 등록 후, 구매자의 주문으로<br/>
                                생산자의 스케쥴링을 수행하도록 함<br/>
                            </p>
                            <p>
                                <span>모니터링: </span>스케쥴 시각화 및 트래킹 기능, 주문 등록, 입찰, 그 외 변경 등에 대한<br/>알람 기능 제공<br/>
                            </p>
                            <p>
                                <span>입찰, 매칭, 평가: </span>주문자의 가격과 생산자의 가격을 입찰하여 매칭 결과를 제공하고<br/>구매자는 이러한 절차를 평가하는 기능<br/>
                            </p>
                        </div>
                    </div>
                </div>
                <div className="strategy">
                    <div className="schedule-dogyo">
                        <Com.StrongStrategy underline={true}>플랫폼 구조</Com.StrongStrategy>
                        <CompImg img={B2} width="100%" height="auto" />
                    </div>
                </div>
                <div className="strategy">
                    <div className="schedule-dogyo">
                        <Com.StrongStrategy underline={true}>적용분야 및 유사서비스</Com.StrongStrategy>
                        <p className="strategy-desc">
                            섬유, 반도체, 그 외 제조분야 잡샵 스케쥴이 필요한 분야<br/>
                            다이텍플랫폼(texnara.com)<br/>
                            VMS 솔루션스(vmsscm.com)<br/>
                            스마트 솔루션(kstec.co.kr)<br/>
                        </p>
                    </div>
                </div>
            </div>
        </Com.DivDetailSection>
    );
}

export default ScheduleDogyo;