import Com from "components/styledComp/CommStyledComp";
import React from "react";
import YouTube from "react-youtube";


const CompYoutube = () => {
  return (
    <Com.DivDetailSection border="false">
      <div>
          <YouTube
              videoId= "2FqvRFkMBo4"
              opts= {{
                      width: "100%",
                      height: "440px",
                      playerVars: {
                                      autoplay: 0, //자동재생 O
                                      rel: 0, //관련 동영상 표시하지 않음 (근데 별로 쓸모 없는듯..)
                                      modestbranding: 1, // 컨트롤 바에 youtube 로고를 표시하지 않음
                                  },
                    }}
              onEnd= {(e) =>  e.target.stopVideo(0) }/>
      </div>
    </Com.DivDetailSection>
    
  );
};

export default CompYoutube;
