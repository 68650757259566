import Com from "components/styledComp/CommStyledComp";
import React from "react";



const CompImg_Grid = ({img, width= "100%", height="100%"}) => {
  return (
    <Com.DivCompImg>
      <p>
        <picture>
          <Com.MiddleGridImg src={img} alt="" width={width} height={height}/>
        </picture>
      </p>
    </Com.DivCompImg>
  );
};

export default CompImg_Grid;
