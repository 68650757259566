import React from "react";
import MainPicture from "components/comp/MainPicture";
import MajorBusiness from "components/comp/MajorBusiness";
import CompanyIntro from "components/comp/CompanyIntro";
import GoDetail from "components/comp/GoDetail";
import MiddleImg from "components/comp/MiddleImg";
import ArchiImg from "assets/images/architecture.jpg"
import LapImg from "assets/images/lap.jpg"
import HomeOfficeImg from "assets/images/homeoffice.jpg"
import OperationWay from "components/comp/OperationWay";
import OperationStrategy from "components/comp/OperationStrategy";
import ScheduleDogyo from "components/comp/ScheduleDogyo";
import GoDetail2 from "components/comp/GoDetail2";
import GoDetailUrl from "components/comp/GoDetailUrl";

const MainPage = () => {

  return (
    <main className="page-main"> 
      <MainPicture img={ArchiImg} alt=""/>
        <section className="company-main">
          <CompanyIntro/>
          <MajorBusiness/>
          <GoDetail to={"about"} page={"회사소개"}/>

          <MiddleImg img={LapImg} width="90%" height="600px"/>

          <OperationWay/>
          <OperationStrategy/>
          <GoDetail to={"competency"} page={"핵심역량"}/>

          <MiddleImg img={HomeOfficeImg} width="90%" height="auto"/>

          <ScheduleDogyo/>
          <section className="prodDt">
            <GoDetail2 to={"product"} page={"제품소개"}/>
            <GoDetailUrl/>
          </section>
        </section>
    </main>
  );
};

export default MainPage;
