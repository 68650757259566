import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const asyncGetNotion = createAsyncThunk(
  'getNotionDbSlice/asyncGetNotion',
  async () => {
    const resp = await axios("/api/getDatabase")
    return resp.data;
  }
)

const getNotionDbSlice = createSlice({
  name:'getNotionDbSlice',
  initialState: {
    data : null,
    loading: true,
    error:null
  },
  extraReducers: (builder)=> {
    builder.addCase(asyncGetNotion.pending, (state)=> {
      state.loading = true;
    })
    builder.addCase(asyncGetNotion.fulfilled, (state, action)=> {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    })
    builder.addCase(asyncGetNotion.rejected, (state)=> {
      state.loading = false;
      state.data = null;
      state.error = "에러가 발생했습니다";
      //에러메세지 사용할거면 아래 주석
      //state.error = action.error.message;
    })
  }
})

export default getNotionDbSlice;
export {asyncGetNotion}
