import React from "react";
import SubPicture from "components/comp/SubPicture";
import HomeOfficeImg from "assets/images/homeoffice.jpg"
import MiddleImg from "components/comp/MiddleImg";
import KeyboardImg from "assets/images/keyboard.jpg"
import PenImg from "assets/images/pen.jpg"
import Lap2 from "assets/images/laptop2.jpg"
import ScheduleDogyo from "components/comp/ScheduleDogyo";
import ScheduleProcess from "components/comp/ScheduleProcess";
import ScheduleAlgo from "components/comp/ScheduleAlgo";
import ScheduleJobShop from "components/comp/ScheduleJobShop";
import CompYoutube from "components/comp/CompYoutube";

const DetailProduct = () => {

  const url = "http://schedule.hyds.kr/"

  return (
    <main className="company page-main company-more-wrap">
       <section className="company-main">
          <SubPicture img={HomeOfficeImg}/>
          <ScheduleDogyo/>
          <MiddleImg img={KeyboardImg} width="100%" height="400px"/>
          <ScheduleProcess/>
          <MiddleImg img={PenImg} width="100%" height="400px"/>
          <ScheduleJobShop/>
          <MiddleImg img={Lap2} width="100%" height="400px"/>
          <ScheduleAlgo/>
          <CompYoutube/>
          <button className="btn btn-more" onClick={()=>{window.open(url)}}>섬유 공정 스케줄러 바로가기</button>
      </section>
    </main>
  );
};

export default DetailProduct;
