import React, { useEffect } from "react";
import "./App.css";
import { Provider } from 'react-redux';
import store from "redux/store";
import MainPage from "pages/MainPage";
import Header from "components/comp/Header";
import DetailCompanyIntro from "pages/DetailCompanyIntro";
import Footer from "components/comp/Footer";
import { Route, Routes, useLocation } from "react-router-dom";
import Location from "pages/Location";
import DetailCompetency from "pages/DetailCompetency";
import DetailProduct from "pages/DetailProduct";
import Notice from "pages/Notice"
import Recruit from "pages/Recruit";
import BoardArticle from "components/comp/BoardArticle";

let currentPath = "";

function App() {

  let location = useLocation();
  
  
  //링크 재클릭시 화면 최상단으로 이동시키기위해
  useEffect(() => {

    const refreshList = ["/recruit","/notice"];
    
    if(currentPath === location.pathname) {
      
      //공지사항이나 채용정보 게시판에서 링크 재클릭시 새로고침
      if(refreshList.includes(currentPath)){
        window.location.reload();
      }else window.scrollTo(0, 0);
    }
    currentPath = location.pathname;

    window.onbeforeunload = function pushRefresh() {
      window.scrollTo(0, 0);
    };
    
  }, [location]);


  return (
    <Provider store = {store}>
      <Header/>
      <main>
        <Routes>
          <Route path="/" element={<MainPage/>}/>
          <Route path="about" element={<DetailCompanyIntro/>}/>
          <Route path="competency" element={<DetailCompetency/>}/>
          <Route path="product" element={<DetailProduct/>}/>
          <Route path="location" element={<Location/>}/>
          <Route path="notice" element={<Notice/>}/>
          <Route path="recruit" element={<Recruit/>}/>
          <Route path="article"  element={<BoardArticle/>} />
        </Routes>
      </main>
      <Footer/>
    </Provider>
  );
}

export default App;
