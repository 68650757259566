import Com from "components/styledComp/CommStyledComp";
import moment from "moment/moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { asyncGetNotion } from "redux/reducers/getNotionDbSlice";
import Loading from "./Loading";

const BoardList = (props) => {
  
  const dispatch = useDispatch();
  const {loading, data, error} = useSelector(state =>{
    return state.getNotionDb;
  });
  
  useEffect(()=>{
    dispatch(asyncGetNotion());
  },[dispatch])
  
  const setPageInfo=(title,date,type,pageId)=>{
    const pageInfo = {title, date, type, pageId}
    localStorage.setItem("pageInfo", JSON.stringify(pageInfo));
  }

  if (error) return <p className="page-heading-desc">{error}</p>;
  if (loading) return <Loading/>;
  
  
  const dataList = props.type==="SHOW"
                  ?
                  data.filter(data => data.name.includes(props.type))
                  :
                  data.filter(data => data.name.includes(props.type1)||data.name.includes(props.type2));

  if(dataList.length>0){
    return (
      <>
        {
          dataList.map((dataList) => {

            const date = moment(dataList.createdTime).format('YY.MM.DD');

            return (
              <Com.DivBoardBox key={dataList.id}>
                <Com.LinkBoard to="/article" onClick={()=>{setPageInfo(dataList.title, date, props.type, dataList.id)}}>
                  <Com.DivBoardContent>
                    <Com.DivBoardTitle>
                      {
                        dataList.name.includes(props.type2)?
                        <div>
                          {dataList.title}(종료)
                        </div>
                        :
                        <div>
                          {dataList.title}
                        </div>
                      }
                    </Com.DivBoardTitle>
                    <div>
                      <Com.TimeBoard>{date}</Com.TimeBoard>
                    </div>
                  </Com.DivBoardContent>
                </Com.LinkBoard>
              </Com.DivBoardBox>
            )}
          )
        }
      </>
    );
  }else{
    return props.type==="SHOW"?<p className="page-heading-desc">공지사항이 없습니다</p>:<p className="page-heading-desc">등록된 채용공고가 없습니다</p>;
  }
};

export default BoardList;
