import Com from "components/styledComp/CommStyledComp";
import React from "react";



const CompImg = ({img, width= "100%", height="100%"}) => {
  return (
    <Com.MiddleImg src={img} alt="" width={width} height={height}/>
  );
};

export default CompImg;
