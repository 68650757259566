import React from "react";
import Com from "components/styledComp/CommStyledComp";

const MainPicture = (props) => {
  return (
    <section className="main-picture">
      <div className="main-content">
        <Com.ImgMain src={props.img}/>
        <div className="season-desc">
          <p>
            <strong>
              TAKE ON<br/>EFFECTIVE VALUE
            </strong>
          </p>
          <p>효과적인 가치를 실현하다</p>
        </div>
      </div>
    </section>
  );
};

export default MainPicture;
